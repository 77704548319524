<template>
  <b-card>
    <validation-observer
      ref="observer"
      v-slot="{ pristine, invalid, handleSubmit }"
    >
      <b-form @submit.prevent="handleSubmit(submit)">
        <advanced-search
          :initial-rules="rules"
          is-filter-group
          @update="updateAddedRules"
          @update:groupName="updateGroupName"
        />
        <b-row>
          <b-col sm="6">
            <preview-count
              :is-fetching="fetchingPreview"
              :user-count-expr="userCountExpr"
            />
          </b-col>
          <b-col sm="6">
            <b-row class="d-flex align-items-end justify-content-end">
              <b-col
                sm="5"
                class="mb-1"
              >
                <b-button
                  block
                  type="reset"
                  variant="outline-dark"
                  @click="clearForm"
                >
                  Cancel
                </b-button>
              </b-col>
              <b-col
                sm="5"
                class="mb-1"
              >
                <b-button
                  block
                  type="submit"
                  variant="primary"
                  :disabled="pristine || invalid || isUpdating"
                  @click="submit"
                >
                  <b-spinner
                    v-if="isUpdating" 
                    small
                  />
                  <span
                    v-if="isUpdating"
                  > Updating...</span>
                  <span v-else>
                    <feather-icon
                      icon="SaveIcon"
                      size="16"
                      class="mr-50"
                    />
                    Save filtered group</span>
                </b-button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>
    
<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BButton,
  VBTooltip,
  BSpinner
} from "bootstrap-vue";
import { getValidationState } from "@/libs/utils";
import { makeErrorToast, makeSuccessToast } from "@/libs/utils";
import { entitysDisplay, SearchExprClass, filters, dateOperators } from '@models';
import { mapGetters } from 'vuex';
import { groupsService, usersService } from "@/services";
import AdvancedSearch from "./AdvancedSearch.vue";  
import { ValidationObserver } from "vee-validate";
import PreviewCount from './partials/PreviewCount.vue';
import _debounce from "lodash/debounce";

export default {
  name: 'CreateFilterGroup',
  components: {
    BCard,
    BRow,
    BCol,
    BForm,
    BButton,
    BSpinner,
    AdvancedSearch,
    ValidationObserver,
    PreviewCount
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      rules: new SearchExprClass({
        type: filters.FILTER_SET_ALL
      }),
      isUpdating: false,
      groupName: '',
      fetchingPreview: false,
      userCountExpr: 0
    };
  },
  computed:{
    ...mapGetters('programs',['programById', 'defaultProgramId']),
  },
  watch: {
    rules: {
      handler(n) {
        if(n.filters.length > 0) {
          this.$nextTick(() => {
            this.validateAndFetchPreview();
          });
        }
      },
      deep: true
    },
  },
  methods: {
    clearForm() {
      this.$router.push({ name: 'champion-participants-groups', id: this.defaultProgramId });
    },
    async submit() {
      try {
        this.isUpdating = true;
        const filterModel = this.adjustBetweenDates();
        const updateModel = {...this.rules, filters: filterModel};
        const searchModel  = await usersService.createUserSearch(this.defaultProgramId, {search_expr: new SearchExprClass({...updateModel})});
        const {users_count, id} = searchModel.data;
        // eslint-disable-next-line camelcase
        this.userCountExpr = users_count;
        this.createFilterGroup(id);       
      } catch (e) {
        this.$toast(makeErrorToast("Error saving search results."));
        console.log(e);
        this.$log.error(e);
      } finally {
        this.isUpdating = false;
      }
    },
    async createFilterGroup(searchId) {
      try {
        this.isUpdating = true;
        const response  = await groupsService.createFilterGroup(this.defaultProgramId, {name: this.groupName, user_search: {id: searchId}});
        const { id } = response.data;
        this.$toast(makeSuccessToast("Filter group created."));
        this.$router.push({ name: 'champion-participants-filter-groups',params: { id: this.defaultProgramId, groupId: id }});
      } catch (e) {
        this.$toast(makeErrorToast("Error saving filter group."));
        console.log(e);
        this.$log.error(e);
      } finally {
        this.isUpdating = false;
      }
    },
    fetchExprResults: _debounce(async function () {
      try {
        const filterModel = this.adjustBetweenDates();
        const updateModel = {...this.rules, filters: filterModel};
        this.fetchingPreview = true;
        const response  = await usersService.previewUserSearch(this.defaultProgramId, {search_expr: new SearchExprClass({...updateModel})});
        const {users_count} = response.data;
        // eslint-disable-next-line camelcase
        this.userCountExpr = users_count;
      } catch (e) {
        this.$toast(makeErrorToast("Error fetching preview search results."));
        console.log(e);
        this.$log.error(e);
      } finally {
        this.fetchingPreview = false;
      }
    }, 1000),
    async updateAddedRules(data) {
      this.rules = {
        ...data
      };
    },
    updateGroupName(name) {
      this.groupName = name;
    },
    async validateAndFetchPreview() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        return;
      }
      this.fetchExprResults();
    },
    adjustBetweenDates() {
      const newArray =  this.rules.filters.map(filter => {
        if(filter.expressions && Array.isArray(filter.expressions)) {
          return [{...filter,expressions: filter.expressions.map(exp => {
            if(String(exp?.op) === String(dateOperators.OP_BETWEEN)) {
              if(!Array.isArray(exp.value)) {
                const dateRange = exp.value.split(" to ");
                if (dateRange.length > 1) {
                  const startDate = dateRange[0];
                  const endDate = dateRange[1];
                  return {...exp, value: [startDate, endDate]};
                }
              }
            }
            return exp;
          })}];
        }
        return filter;
      });
      return newArray.flat();
    }
  },
  setup(){
    return {
      getValidationState,
      entitysDisplay
    };
  }
};
</script>