<template>
  <validation-provider
    v-slot="validationContext"
    :ref="`string-${index}`"
    rules="required"
    :vid="`string-${index}`"
    :name="`Value`"
  >
    <b-form-group
      label-for="Component"
    >
      <b-form-input
        id="advanced-search-string"
        :value="value"
        placeholder="type..." 
        autofocus
        :state="
          getValidationState(
            validationContext
          )
        "
        @input="$emit('input', $event)"
      />
      <b-form-invalid-feedback
        :state="
          getValidationState(
            validationContext
          )
        "
      >
        {{
          validationContext.errors[0]
        }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
    
<script>
import {
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback
} from "bootstrap-vue";

import { ValidationProvider } from "vee-validate";
import {
  getValidationState
} from "@/libs/utils";
export default {
  name: 'AdvancedSearchString',
  components: {
    BFormInput,
    BFormGroup,
    ValidationProvider,
    BFormInvalidFeedback
  },
  props: {
    value: {
      type: [String, Array, Number],
      default: ''
    },
    index: {
      type: Number,
      default: 0
    }
  },
  setup() {
    return {
      getValidationState
    };
  }
};
</script>
    