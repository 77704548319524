<template>
  <validation-provider
    v-slot="validationContext"
    :ref="`string-${index}`"
    rules="required"
    :vid="`string-${index}`"
    :name="`Value`"
  >
    <b-form-group
      label-for="Component"
    >
      <flat-pickr
        id="advanced-search-date"
        :value="value"
        class="form-control"
        placeholder="Select a Date"
        :config="config"
        :state="
          getValidationState(
            validationContext
          )
        "
        @input="emitChange($event)"
      />
      <b-form-invalid-feedback
        :state="
          getValidationState(
            validationContext
          )
        "
      >
        {{
          validationContext.errors[0]
        }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
      
<script>
import {
  BFormGroup,
  BFormInvalidFeedback
} from "bootstrap-vue";
  
import { ValidationProvider } from "vee-validate";
import {
  getValidationState
} from "@/libs/utils";
import { PRIMARY_DATE_FORMAT } from "@/libs/utils";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import "flatpickr/dist/themes/material_blue.css";

export default {
  name: 'AdvancedSearchDate',
  components: {
    BFormGroup,
    ValidationProvider,
    BFormInvalidFeedback,
    flatPickr
  },
  props: {
    value: {
      type: [Date, String, Array],
      default: () => new Date()
    },
    index: {
      type: Number,
      default: 0
    },
    isRange: {
      type: Boolean,
    }
  },
  watch: {
    isRange(n) {
      if(n) {
        this.config = {
          ...this.config,
          mode: "range"
        };
      }
      else {
        this.config = {
          ...this.config,
          mode: 'single'
        };
      }
    }
  },
  methods: {
    emitChange(val) {
      if(this.isRange) {
        if(val.includes('to')) {
          return this.$emit('input', val);
        }
        return;
      }
      return this.$emit('input', val);
    }
  },
  setup(props) {
    const mode = props.isRange ? "range" : "single";
    const config = {
      defaultDate: 'today',
      enableTime: false,
      dateFormat: 'Z', 
      allowInput: true,
      altInput: true,
      altFormat: PRIMARY_DATE_FORMAT,
      mode: mode
    };

    return {
      config,
      getValidationState
    };
  }
};
</script>
      