<template>
  <b-form-group
    label-for="Component"
  >
    <v-select
      :value="value"
      placeholder="Value"
      :options="options"
      label="text"
      :clearable="false"
      :reduce="(option) => option.value"
      @input="$emit('input', $event)"
    />
  </b-form-group>
</template>
      
<script>
import {
  BFormGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  name: 'AdvancedDropDown',
  components: {
    BFormGroup,
    vSelect
  },
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    options: {
      type: Array,
      default: () => []
    }
  }
};
</script>
      