<template>
  <p
    v-if="userCountExpr !== -1 && !fetchingPreview"
  >
    The current rules will result in {{ userCountExpr }} participants.
  </p>
</template>
<script>
export default {
  name: 'PreviewCount',
  props: {
    userCountExpr: {
      type: [String, Array, Number],
      default: ''
    },
    fetchingPreview: {
      type: Number,
      default: 0
    }
  }
};
</script>