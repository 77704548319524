<template>
  <b-col sm="12">
    <validation-provider
      v-slot="validationContext"
      ref="filteredGroupName"
      rules="required"
      vid="group-name"
      name="Group Name"
    >
      <b-form-group
        label-for="Component"
      >
        <b-row>
          <b-col sm="2">
            <h5>Filtered Group Name</h5>
          </b-col>
          <b-col sm="3">
            <b-form-input
              id="filter-group-name"
              :value="value"
              placeholder="Group Name" 
              autofocus
              maxlength="255"
              :state="
                getValidationState(
                  validationContext
                )
              "
              @input="$emit('input', $event)"
            />
            <b-form-invalid-feedback
              :state="
                getValidationState(
                  validationContext
                )
              "
            >
              {{
                validationContext.errors[0]
              }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
      </b-form-group>
    </validation-provider>
  </b-col>
</template>
<script>
import {
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import { ValidationProvider } from "vee-validate";
import { getValidationState } from "@/libs/utils";

export default {
  name: 'FilterGroupName',
  components: {
    BCol,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    ValidationProvider,
    BRow
  },
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  setup(){
    return {
      getValidationState,
    };
  }
};
</script>