<template>
  <ValidationObserver>
    <section :class="{ 'added-rules-wrapper': background }">
      <section
        v-for="(rule, i) in model.filters"
        :key="i"
      >
        <hr>
        <b-row>
          <b-col sm="9">
            <h3 class="mb-2 mt-2">
              {{ entityTypeName(rule.type) }}
            </h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            v-if="i === 0 && rule.aggregate"
            md="1"
          >
            <p class="mt-50 mb-0">
              Where
            </p>
          </b-col>
          <b-col
            v-if="rule.aggregate && Object.keys(rule.aggregate).length !== 0"
            sm="3"
            md="3"
          >
            <b-form-group label-for="Aggregate">
              <v-select
                v-model="rule.aggregate.type"
                placeholder="Aggregate"
                :options="rule.aggregate.typeDisplay"
                label="name"
                :clearable="false"
                :reduce="(option) => option.id"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="rule.aggregate && Object.keys(rule.aggregate).length !== 0"
            sm="3"
            md="2"
          >
            <b-form-group
              v-if="isAgTypeCount(rule.aggregate.type)"
              label-for="operator"
            >
              <v-select
                v-model="rule.aggregate.op"
                placeholder="Aggregate"
                :options="rule.aggregate.operatorDisplay"
                label="name"
                :clearable="false"
                :reduce="(option) => option.id"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="rule.aggregate"
            sm="3"
            md="4"
          >
            <string-component
              v-if="isAgTypeCount(rule.aggregate.type)"
              v-model="rule.aggregate.value"
            />
          </b-col>
        </b-row>
        <b-row
          v-for="(exp, index) in rule.expressions"
          :key="index"
        >
          <b-col
            v-if="index === 0 && rule.expressions[index]"
            md="1"
          >
            <p class="mt-50 mb-0">
              and
            </p>
          </b-col>
          <b-col
            v-if="index > 0 && rule.expressions[index]"
            md="1"
          >
            <p class="mt-50 mb-0">
              and
            </p>
          </b-col>
          <b-col
            v-if="rule.expressions[index]"
            md="3"
          >
            <b-form-group label-for="Field">
              <v-select
                ref="field"
                v-model="rule.expressions[index].field"
                placeholder="Field"
                :options="rule.fieldDisplay"
                label="name"
                :clearable="false"
                :reduce="(option) => option.id"
                @input="onChangeField(index, i)"
              />
            </b-form-group>
            <b-button
              v-if="
                rule.expressions.length - 1 === index && rule.expressions[index]
              "
              class="add-custom-btn mt-1 mb-2 full-width text-left pl-0"
              variant="outline-none"
              size="sm"
              @click="$emit('addExpression', rule.type, i)"
            >
              <feather-icon
                icon="PlusIcon"
                size="20"
                class="mr-50"
              />
              <span
                class="text-nowrap"
              ><h5 class="d-inline-block mb-0">Add Expression</h5></span>
            </b-button>
          </b-col>
          <b-col
            v-if="rule.expressions[index]"
            md="2"
          >
            <b-form-group label-for="Operator">
              <v-select
                ref="operator"
                v-model="rule.expressions[index].op"
                placeholder="Operator"
                :options="rule.operatorDisplay[rule.expressions[index].field]"
                label="name"
                :clearable="false"
                :reduce="(option) => option.id"
                @input="onChangeOperator(index, i)"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="
              rule.component &&
                rule.expressions[index] &&
                !isObjectInstance(rule.component[rule.expressions[index].field])
            "
            md="4"
          >
            <!-- String and Dropdown -->
            <component
              :is="rule.component[rule.expressions[index].field]"
              v-model="rule.expressions[index].value"
              :options="rule.valueDisplay[rule.expressions[index].field]"
              :index="index"
              :service="rule.service[rule.expressions[index].field]"
              :rule="rule"
              :is-range="isOperaterDateAndRange(rule.expressions[index])"
            />
          </b-col>
          <b-col
            v-if="
              rule.component &&
                rule.expressions[index] &&
                isObjectInstance(rule.component[rule.expressions[index].field])
            "
            md="4"
          >
            <section
              v-if="
                Array.isArray(
                  rule.component[rule.expressions[index].field][
                    rule.expressions[index].op
                  ]
                )
              "
            >
              <b-row>
                <b-col
                  v-for="(comp, c) in rule.component[
                    rule.expressions[index].field
                  ][rule.expressions[index].op]"
                  :key="c"
                  sm="6"
                  class="d-flex flex-row"
                >
                  <component
                    :is="comp"
                    v-if="
                      rule.expressions[index].value &&
                        rule.expressions[index].value.length > 0
                    "
                    v-model="rule.expressions[index].value[c]"
                    :options="rule.valueDisplay[rule.expressions[index].field]"
                    :index="index"
                    :service="rule.service[rule.expressions[index].field]"
                    :rule="rule"
                    :is-range="isOperaterDateAndRange(rule.expressions[index])"
                  />
                  <span
                    v-if="c === 0 && rule.expressions[index]"
                    class="mt-50 pl-1"
                  >and</span>
                </b-col>
              </b-row>
            </section>
            <section v-else>
              <component
                :is="
                  rule.component[rule.expressions[index].field][
                    rule.expressions[index].op
                  ]
                "
                v-if="rule.expressions[index]"
                v-model="rule.expressions[index].value"
                :options="rule.valueDisplay[rule.expressions[index].field]"
                :index="index"
                :service="rule.service[rule.expressions[index].field]"
                :rule="rule"
                :is-range="isOperaterDateAndRange(rule.expressions[index])"
              />
            </section>
          </b-col>
          <b-col
            v-if="rule.expressions[index]"
            md="2"
          >
            <b-button
              class="delete-rule-btn"
              variant="outline-none"
              @click="
                $emit('removeExpression', { filterIndex: i, exprIndex: index })
              "
            >
              <feather-icon
                icon="TrashIcon"
                size="21"
                class="mr-50"
              />
            </b-button>
          </b-col>
        </b-row>
      </section>
    </section>
  </ValidationObserver>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BButton,
  BFormRadio,
  VBTooltip,
  BFormInput,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ValidationObserver } from "vee-validate";
import { getValidationState } from "@/libs/utils";
import { entitysDisplay, aggregateTypes, dateOperators, entitysPartPreferDisplay } from "@models";
import StringComponent from "./StringComponent.vue";
import DropDownComponent from "./DropDownComponent.vue";
import DateComponent from "./DateComponent.vue";
import ServiceComponent from "./ServiceComponent.vue";

export default {
  name: "RuleBuilder",
  components: {
    BFormInput,
    BFormGroup,
    BCol,
    BCard,
    BRow,
    BFormGroup,
    BForm,
    BButton,
    BFormRadio,
    ValidationObserver,
    vSelect,
    StringComponent,
    VBTooltip,
    DropDownComponent,
    DateComponent,
    ServiceComponent,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    model: {
      type: Object,
      default: () => {},
    },
    background: {
      type: Boolean,
    },
    addRule: {
      type: Boolean,
    },
    entity: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      agTypes: [],
    };
  },
  methods: {
    onChangeField(innerIndex, outerIndex) {
      this.$emit("fieldUpdate", { inner: innerIndex, outer: outerIndex });
    },
    onChangeOperator(innerIndex, outerIndex) {
      this.$emit("operatorUpdate", { inner: innerIndex, outer: outerIndex });
    },
    onAggregateTypeChange(rule, type) {
      rule.aggregate.type = type;
    },
    entityTypeName(type) {
      return [...entitysDisplay, ...entitysPartPreferDisplay].filter(e => e.id === type)[0]?.name || "";
    },
    isOperaterDateAndRange(exp) {
      return String(exp?.op) === String(dateOperators.OP_BETWEEN);
    },
    isObjectInstance(exp) {
      return exp instanceof Object;
    },
    isAgTypeCount(type) {
      return type === aggregateTypes.COUNT;
    },
  },
  setup() {
    return {
      getValidationState,
      aggregateTypes,
    };
  },
};
</script>
<style lang="scss" scoped>
.added-rules-wrapper {
  background: #f9f8f8;
  padding: 20px;
  border-radius: 5px;
  .form-group {
    background: white;
  }
}
</style>
